'use strict';

var $ = require('jquery');
var Swiper = require('swiper');

var Products = function() {
    var swiper = new Swiper('.swiper-container-vert', {
        direction: 'vertical',

        pagination: {
            el: '.swiper-pagination',
            bulletElement: 'span',
            bulletClass: 'swiper-page-lines',
            bulletActiveClass: 'active-slide'
        },

        keyboard: {
            enabled: true
        },

        mousewheel: {
            forceToAxis: true,
            invert: true
        }
    });

    // $(document).ready(function() {
    //     var swiper = new Swiper('.swiper-container-vert', {
    //         direction: 'vertical',
    
    //         pagination: {
    //             el: '.swiper-pagination',
    //             bulletElement: 'span',
    //             bulletClass: 'swiper-page-lines',
    //             bulletActiveClass: 'active-slide'
    //         },

    //         keyboard: {
    //             enabled: true
    //         },

    //         mousewheel: {
    //             forceToAxis: true,
    //             invert: true
    //         }
    //     });
    // });
};

module.exports = Products;