'use strict';
global.jQuery = require('jquery');
var $ = require('jquery');
var Swiper = require('swiper');
var Home = require('./home');
var Header = require('../_modules/header/header');
var LatteArt = require('./latteArt');
var Products = require('./termekek');
var Lightbox = require('./lightbox');
var cookieconsent = require('./cookie_consent');
var acedemy_video1 = '<iframe width="800" height="450" src="https://www.youtube.com/embed/AboP_tz_CsI?autoplay=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';
var acedemy_video2 = '<iframe width="800" height="450" src="https://www.youtube.com/embed/DxZeqYzV32k?autoplay=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';
var acedemy_video3 = '<iframe width="800" height="450" src="https://www.youtube.com/embed/P5gb7veZzPU?autoplay=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';
var acedemy_video4 = '<iframe width="800" height="450" src="https://www.youtube.com/embed/x3ogokCgEkg?autoplay=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';
$(function() {
    $(document).ready(function() {
        cookieconsent.run({"notice_banner_type":"simple","consent_type":"express","palette":"light","change_preferences_selector":"#changePreferences","language":"hu","website_name":"OMNIA","cookies_policy_url":"https://www.omnia.hu/cookie-policy"});
        $('.video').on('click', function() {
            switch($(this).attr('id')) {
                case 'acedemy_video1':
                    $('.popup_wrapper').html(acedemy_video1);
                    break;
                case 'acedemy_video2':
                    $('.popup_wrapper').html(acedemy_video2);
                    break;
                case 'acedemy_video3':
                    $('.popup_wrapper').html(acedemy_video3);
                    break;
                case 'acedemy_video4':
                    $('.popup_wrapper').html(acedemy_video4);
                    break;
            }
            $('.popup').removeClass('hidden'); 
        });
        $('.popup_close, .popup').on('click', function(){
            $('.popup').addClass('hidden');
            $('.popup_wrapper').html('');
        });
        $('.floatmenu').on('click', function() {
            $("html, body").animate({
                    scrollTop: $("#" + this.dataset.menu).offset().top - 80
            }, 1000);
        });
        $('.otherpixbutton').on('click', function() {
            $(".recipe").each(function() {
                $(this).removeClass('hidden').find('a').attr('data-lightbox','gallery');
            });
            $(this).hide();
        });
    });
    new Home();
    new Header();
    new LatteArt();
    new Products();
    $( window ).scroll(function() {
        $('.anim:not(.animated)').each(function(index, obj) {
            if($(window).scrollTop() > obj.offsetTop) {
                obj.className += ' animated';
            }
        })
    });
});