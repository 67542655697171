'use strict';

var $ = require('jquery');
var Swiper = require('swiper');

var LatteArt = function() {
    // var _this = this;
    
    // var swiper = new Swiper('.swiper-container-latte', {
    //     direction: 'horizontal',
    //     slidesPerView: 1,
    //     spaceBetween: 30,
    //     pagination: {
    //       el: '.swiper-pagination-latte'
    //     }
    // });

    // $('div.reference_block').on('click', function() {
    //     var previousDescIndex = $('div.reference_block.selected').index();

    //     _this.showDescription($(this).index(), previousDescIndex);
    //     $('div.reference_block').removeClass('selected');
    //     _this.moveBorder($(this));
    //     $(this).addClass('selected');
    // });

    // this.moveBorder = function(selectedBox) {
    //     var wrapperLeftOffset = Math.ceil($('div.reference_wrapper.latte_art').offset().left);
    //     var leftOffset = Math.ceil(selectedBox.offset().left) - wrapperLeftOffset;
    //     $('div.border_selection').animate({ left: leftOffset - 10 }, 200);
    // }

    // this.showDescription = function(index, previousIndex) {
    //     $('div.variaton_container').eq(previousIndex - 1).hide();
    //     $('div.variaton_container').eq(index - 1).show();
    // };
};

module.exports = LatteArt;