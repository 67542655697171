'use strict';

var $ = require('jquery');

var Header = function() {
    var _this = this;
    var timeOut = null;
    var mobileMenuOpened = false;
    var pathName = window.location.pathname.split('/')[1];
    var productSubpageName = window.location.pathname.split('/')[2];
    var productSubSubpageName = window.location.pathname.split('/')[3];
    var pages = [
        'omnia-barista-editions',
        'barista-academy',
        'kapszulak',
        'termekek',
        'az-omnia-kaverol',
        'kapcsolat'
    ];
    var productSubPages = [
        'orolt-porkolt-kavek',
        'szemes-kavek',
        'kapszulak',
        'barista-editions'
    ];
    var productSubSubPages = [
        'omnia-classic',
        'omnia-gold',
        'omnia-espresso',
        'omnia-moments',
        'omnia-silk',
        'omnia-blonde',
        'omnia-evening',
        'omnia-classic',
        'omnia-gold',
        'omnia-espresso',
        'omnia-silk',
        'omnia-espresso-classico',
        'omnia-espresso-crema',
        'omnia-espresso-guatemala',
        'omnia-espresso-ristretto',
        'omnia-barista-editions-cremoso',
        'omnia-barista-editions-cortado',
        'omnia-barista-editions-espresso-mezzo',
        'omnia-barista-editions-arabica',
        'omnia-barista-editions-cremoso-intenso'
    ];

    this.addActiveClass = function(currentPage) {
        console.log(currentPage);
        if (pathName === 'termekek') {
            $('ul.menu > .dropdown_container > a').each(function() {
                if ($(this)[0].dataset.href.split('/')[1] === currentPage) {
                    $(this).addClass('active');
                }
            });
            $('ul.menu > .dropdown_container > .dropdown_list > .dropdown_container > a.submenu').each(function() {
                if ($(this)[0].dataset.href.split('/')[2] === currentPage) {
                    $(this).addClass('active');
                }
            });
            $('ul.menu > .dropdown_container > .dropdown_list > .dropdown_container > .dropdown_list > li > a').each(function() {
                if ($(this)[0].attributes[0].nodeValue.split('/')[3] === currentPage && $(this)[0].parentElement.parentElement.parentElement.childNodes[0].classList[1] === 'active') {
                    $(this).addClass('active');
                }
            });
        } else {
            $('ul.menu > li > a.hover').each(function() {
                if ($(this)[0].attributes[1].nodeValue.split('/')[1] === currentPage || $(this)[0].attributes[1].nodeValue === currentPage) {
                    $(this).addClass('active');
                }
            });
        }
    };

    this.mouseIn = function() {
        if (this.children.length > 0) {
            var activemenu = this.children[1];
        } else {
            var activemenu = this;
        }
        $(activemenu).addClass('flex');
        clearTimeout(timeOut);
    };
    
    this.mouseOut = function(e) {
        timeOut = setTimeout(function() {
            if (e.currentTarget.children > 0) {
                var activemenu = e.currentTarget.children[1];
            } else {
                var activemenu = e.currentTarget;
                $($(activemenu)[0].nextElementSibling).removeClass('flex');
            }
            $(activemenu).removeClass('flex');
        }, 100);
    };

    this.animateMobileMenu = function() {
        if ($('div.together-container_mobil').css('display') === 'none') {
            $('div.together-container_mobil').css('display', 'flex');
        } else {
            $('div.together-container_mobil').css('display', 'none');
        }
    }

    this.checkIfProductsPage = function() {
        if (pathName === pages[0]) {
            if (mobileMenuOpened) {
                $('header nav.navbar.light').css('background', '#ffffff');
                $('header nav.navbar.dark').css('background', '#ffffff');
                $('header nav.navbar.transparent').css('background', 'transparent');
            } else {
                $('header nav.navbar.light').css('background', 'rgba(0, 0, 0, 0.5)');
                $('header nav.navbar.dark').css('background', 'rgba(255, 255, 255, 0.7)');
                $('header nav.navbar.transparent').css('background', 'transparent');
            }
        }
    }

    // *********
    // EVENTS
    // *********

    $('li.dropdown_container').hover(this.mouseIn, this.mouseOut);
    $('ul.dropdown_list').hover(this.mouseIn, this.mouseOut);
    $('a.submenu').hover(this.mouseIn, this.mouseOut);
    $('img.MMenu').on('click', function() {
        mobileMenuOpened = !mobileMenuOpened;
        _this.checkIfProductsPage();
        _this.animateMobileMenu();
    });

    // *********
    // EVENTS END
    // *********

    $(document).ready(function() {
        switch (pathName) {
            case pages[0]:
                _this.addActiveClass(pages[0]);
                break;
            case pages[1]:
                _this.addActiveClass(pages[1]);
                break;
            case pages[2]:
                _this.addActiveClass(pages[2]);
                break;
            case pages[3]:
                _this.addActiveClass(pages[3]);
                break;
            case pages[4]:
                _this.addActiveClass(pages[4]);
                break;
            case pages[5]:
                _this.addActiveClass(pages[5]);
                break;
            default:
                _this.addActiveClass('/');
                break;
        }
        
        switch (productSubpageName) {
            case productSubPages[0]:
                _this.addActiveClass(productSubPages[0]);
                break;
            case productSubPages[1]:
                _this.addActiveClass(productSubPages[1]);
                break;
            case productSubPages[2]:
                _this.addActiveClass(productSubPages[2]);
                break;
            case productSubPages[3]:
                _this.addActiveClass(productSubPages[3]);
                break;
            default:
                break;
        }
        
        switch (productSubSubpageName) {
            case productSubSubPages[0]:
                _this.addActiveClass(productSubSubPages[0]);
                break;
            case productSubSubPages[1]:
                _this.addActiveClass(productSubSubPages[1]);
                break;
            case productSubSubPages[2]:
                _this.addActiveClass(productSubSubPages[2]);
                break;
            case productSubSubPages[3]:
                _this.addActiveClass(productSubSubPages[3]);
                break;
            case productSubSubPages[4]:
                _this.addActiveClass(productSubSubPages[4]);
                break;
            case productSubSubPages[5]:
                _this.addActiveClass(productSubSubPages[5]);
                break;
            case productSubSubPages[6]:
                _this.addActiveClass(productSubSubPages[6]);
                break;
            case productSubSubPages[7]:
                _this.addActiveClass(productSubSubPages[7]);
                break;
            case productSubSubPages[8]:
                _this.addActiveClass(productSubSubPages[8]);
                break;
            case productSubSubPages[9]:
                _this.addActiveClass(productSubSubPages[9]);
                break;
            case productSubSubPages[10]:
                _this.addActiveClass(productSubSubPages[10]);
                break;
            case productSubSubPages[11]:
                _this.addActiveClass(productSubSubPages[11]);
                break;
            case productSubSubPages[12]:
                _this.addActiveClass(productSubSubPages[12]);
                break;
            case productSubSubPages[13]:
                _this.addActiveClass(productSubSubPages[13]);
                break;
            case productSubSubPages[14]:
                _this.addActiveClass(productSubSubPages[14]);
                break;
            case productSubSubPages[15]:
                _this.addActiveClass(productSubSubPages[15]);
                break;
            case productSubSubPages[16]:
                _this.addActiveClass(productSubSubPages[16]);
                break;
            case productSubSubPages[17]:
                _this.addActiveClass(productSubSubPages[17]);
                break;
            case productSubSubPages[18]:
                _this.addActiveClass(productSubSubPages[18]);
                break;
            case productSubSubPages[19]:
                _this.addActiveClass(productSubSubPages[19]);
                break;
            default:
                break;
        }
    });
};

module.exports = Header;