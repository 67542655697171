'use strict';

var $ = require('jquery');
var Swiper = require('swiper');

var Home = function() {
    var slides = [0, 1, 2];
    var currentSlide = 0;

    $(document).ready(function() {
        var swiper = new Swiper('.swiper-container', {
            // Optional parameters
            direction: 'horizontal',
            slidesPerView: 1,
            spaceBetween: 50,
            loop: true,

            autoplay: {
				delay: 5000,
				disableOnInteraction: false
            },
            
            effect: 'coverflow',
            coverflowEffect: {
                rotate: 50,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows : true
            },
    
            // If we need pagination
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                bulletElement: 'span',
                bulletClass: 'dot',
                bulletActiveClass: 'active_slide',
                clickable: true
            },
    
            // Navigation arrows
            navigation: {
                nextEl: '.nav_arrow_next',
                prevEl: '.nav_arrow_prev',
            }
        });
    });
};

module.exports = Home;